import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Drawer, Hidden, List, makeStyles} from '@material-ui/core';
import NavItem from './NavItem';
import {withTranslation} from "react-i18next";

/**
 * サイドバーのナビゲーションアイテム
 * activeOverride = urlがこのサイドバーアイテムにマッチした場合、ハイライトします。
 */
const items = [
    {
        href: '/manager/accounts',
        title: 'user_account'
    },
    {
        href: '/manager/users',
        title: 'user'
    },
];

/**
 * コンポーネントスティール
 */
const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 220
    },
    desktopDrawer: {
        width: 220,
        top: 65,
        height: 'calc(100% - 64px)'
    },
}));

/**
 * ナビゲーションバーコンポーネント
 *
 * @param onMobileClose
 * @param openMobile
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
const NavBar = ({onMobileClose, openMobile, t}) => {
    const classes = useStyles();
    const location = useLocation();
    // モバイル/デスクトップの状態を確認
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box paddingLeft={3} paddingY={2} paddingRight={2}>
                <List>
                    {items.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            style={{marginTop:2}}
                            title={t('navigation.manager.' + item.title)}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1}/>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => {
    },
    openMobile: false
};

export default withTranslation('common')(NavBar);
