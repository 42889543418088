/***
 * //////////////////////////////////////////////////////////////
 * TYPES (タイプ)
 * ----------------------
 *
 * タイプは、アクション、リデューサ、saga(または他の)ミドルウェアで使用するための定数です。
 * Typeはアクションの中で、ミドルウェアやレデューサーに命令を伝えるために使われます。
 * 全てのアクションタイプは、グループ化しやすいように以下の配列に格納され、選択されます。
 * //////////////////////////////////////////////////////////////
 */

export const ERROR_OCCURRED_EVENT = 'ERROR_OCCURRED_EVENT'
export const ERROR_CONFIRM_EVENT = 'ERROR_CONFIRM_EVENT'

export const GET_MANAGER_PROFILE = 'GET_MANAGER_PROFILE'
export const GET_MANAGER_PROFILE_SUCCESS = 'GET_MANAGER_PROFILE_SUCCESS'
export const GET_MANAGER_PROFILE_FAILURE = 'GET_MANAGER_PROFILE_FAILURE'

export const ACCESS_TOKEN_FAILURE = "ACCESS_TOKEN_FAILURE"

/***
 * デバッグ用のみ
 * @type {string}
 */
export const DEBUG_RESPONSE = "DEBUG_RESPONSE"
export const DEBUG_RESPONSE_CLEAR = "DEBUG_RESPONSE_CLEAR"

const types = {
    ERROR_OCCURRED_EVENT,
    ERROR_CONFIRM_EVENT,

    GET_MANAGER_PROFILE,
    GET_MANAGER_PROFILE_SUCCESS,
    GET_MANAGER_PROFILE_FAILURE,

    ACCESS_TOKEN_FAILURE,
    DEBUG_RESPONSE,
    DEBUG_RESPONSE_CLEAR
}
export default types;
