import {userListOperations} from "../../../../state/ducks/user/users";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import UserView from "../../../components/user/users/Page";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,

        loading: state.user.loading,
        users: state.user.userList,
        isRegisterView: state.user.isRegisterView,
        userRegistrationLoad: state.user.registerLoading,
        registerError: state.user.registerError,
        searchLoad: state.user.searchLoad,
        searchUserResult: state.user.searchUserResult,
        error: state.user.error,
        userList_CurrentPage: state.user.userList_CurrentPage,
        activeProfile: state.user.activeProfile,
        deleteView: state.user.deleteView,
        deleteLoading: state.user.deleteLoading,
        deleteError: state.user.deleteError
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    toggleRegisterView: userListOperations.toggleRegister,
    registerUser: userListOperations.registerUser,
    searchUser: userListOperations.requestSearchUsers,
    changePage: userListOperations.userChangePage,
    updateUser: userListOperations.updateUser,
    viewUserDeleteToggle: userListOperations.viewUserDeleteToggle,
    deleteUsers: userListOperations.deleteUsers
}

/**
 * Redux
 * i18next Translations
 * を
 * UserView コンポーネントに追加する。
 */
const UserAccountContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(UserView)

export default UserAccountContainer;
