import actions from "./actions";

/***
 * /////////////////////////////////////////////////////////
 * OPERATIONS オペレーション
 * --------------------
 *
 * ミドルウェアを必要とするすべてのアクションまたはアクションのためのバインドで、一緒にバインドされます。
 * このファイルでは、ミドルウェアのインジェクションを必要とするアクションはありませんが、その例がここにあります。
 * 例: https://github.com/alexnm/re-ducks#operations (英語)
 * ////////////////////////////////////////////////////////
 */
const errorConfirm = actions.error_confirm;
const errorRaise = actions.error_raise;

// プロファイル取得リクエスト
const getProfile = actions.getProfile;
const getProfileSuccess = actions.getProfileSuccess;
const getProfileFailure = actions.getProfileFailure;

const accessTokenFailure = actions.accessTokenFailure;

const operations = {
    errorConfirm,
    errorRaise,

    getProfile,
    getProfileSuccess,
    getProfileFailure,

    accessTokenFailure
};
export default operations;
