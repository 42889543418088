import React from 'react';
import PropTypes from 'prop-types';
import {Grid, TextField} from '@material-ui/core';
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../../state/utils/Auth0Utils";
import debounce from "lodash/debounce";

/**
 *
 * @param className
 * @param searchAccount
 * @param t
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const Toolbar = ({className, searchAccount, t, ...rest}) => {
    // Auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();

    return (
        <Grid container alignItems={"flex-end"}>
            <Grid item>
                {/* アカウント検索のオートサジェスト欄 */}
                <TextField id="outlined-basic" onChange={debounce((e) =>
                    (async () => {
                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                            () => {
                                return getAccessTokenSilently();
                            },
                        );
                        searchAccount(e.target.value, access_token);
                    })(), process.env.REACT_APP_SEARCH_TIMEOUT_MILLISECONDS)}
                           placeholder={t('accounts.search')} fullWidth size={"small"}
                           style={{backgroundColor: "#FFFFFF", color: "#868686!important", width: "400px"}}
                           variant="outlined"/>
            </Grid>
            <Grid item>
                {/* FIXME: To add the search button or not */}
                {/*<Button variant={"contained"} style={{height:"53px", backgroundColor:""}}>*/}
                {/*    <SearchIcon style={{ fontSize:"35px"}}/>*/}
                {/*</Button>*/}
            </Grid>
        </Grid>

    );
}

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
