import ApiUtils from "../../utils/ApiUtils";
import {CALL_PROFILE} from './constants'
/***
 * ///////////////////////////////////////////////
 * API
 * -----------------
 *
 * /managerAccountsのすべてのAPIイベントは、ここで呼び出されます。これらのメソッドは、
 * 主にSAGASから呼び出され、APIイベントは、エラーまたはデータオブジェクトを戻します。
 * ///////////////////////////////////////////////
 */

require('dotenv').config()

/**
 * APIからのプロファイル取得
 * @param {string} user - Auth0のユーザーID
 * @param {string} accessToken - auth0 アクセストークン
 * @returns {AxiosPromise}
 */
export function callManagerProfileAPI(user, accessToken) {
    return new ApiUtils(accessToken, null, process.env.REACT_APP_MANAGER_PROFILE_DOMAIN).get(
        CALL_PROFILE + user,
        null
    );
}
