import {default as types} from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (レデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * 全てのアプリケーションでグローバルに使用されます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているmanagerAccountsグループに束縛されています。
 * //////////////////////////////////////////
 */

/**
 * Reactのユーザー一時的なデータ
 */
export const managerUserListState = {
    managerIsRegisterView: false,
    managerRegisterLoading: false,
    managerRegisterError: "",

    managerLoading: true,
    managerIsError: false,

    managerSearchLoad: false,
    managerSearchUserResult: {},

    managerError: {},
    managerUserList: {},
    // managerUserList_RowLimit: null,
    managerUserList_CurrentPage: 0,

    managerDeleteUserView: false,
    managerDeleteError: {},
    managerDeleteLoading: false,
    managerActiveId: null
};

/**
 * Reactのユーザー一時的なデータセッター
 *
 * @param state - User State
 * @param action - イベント(actions)データ
 */
export default function userList(state = managerUserListState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // ユーザー一覧取得
        // -----------------------------------------------
        case types.MANAGER_REQUEST_USERS_LOADING:
            return {
                ...state,
                managerLoading: state.managerLoading = true,
                managerIsError: state.managerIsError = false
            }
        case types.MANAGER_REQUEST_USERS_SUCCESS:
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerUserList: state.managerUserList = action.payload.data.users,
                managerIsError: state.managerIsError = false,
                managerError: state.managerError = {},
            }
        case types.MANAGER_REQUEST_USERS_FAILURE:
            return {
                ...state,
                managerLoading: state.managerLoading = false,
                managerError: state.managerError = action.payload.managerError,
                managerIsError: state.managerIsError = true,
            }
        case types.MANAGER_CHANGE_PAGE:
            return {
                ...state,
                managerUserList_CurrentPage: state.managerUserList_CurrentPage = action.payload.page_no
            }
        // -----------------------------------------------
        // ユーザー登録
        // -----------------------------------------------
        case types.MANAGER_TOGGLE_REGISTER:
            return {
                ...state,
                managerIsRegisterView: !state.managerIsRegisterView,
                managerActiveId: state.managerActiveId = action.payload.user,
                managerRegisterError: state.managerRegisterError = "",
            }
        case types.MANAGER_REGISTER_LOADING:
            return {
                ...state,
                managerRegisterError: state.managerRegisterError = "",
                managerRegisterLoading: state.managerRegisterLoading = true
            }
        case types.MANAGER_ADD_USER_SUCCESS:
            return {
                ...state,
                managerIsRegisterView: state.managerIsRegisterView = false,
                managerRegisterLoading: state.managerRegisterLoading = false,
                managerRegisterError: state.managerRegisterError = "",
            }
        case types.MANAGER_ADD_USER_FAILURE:
            return {
                ...state,
                managerRegisterLoading: state.managerRegisterLoading = false,
                managerRegisterError: state.managerRegisterError = action.payload.managerError,
            }
        // -----------------------------------------------
        // ユーザー検索リクエスト
        // -----------------------------------------------
        case types.MANAGER_SEARCH_USER_LOADING:
            return {
                ...state,
                managerSearchLoad: state.managerSearchLoad = true,
                managerIsError: state.managerIsError = false,
            }
        case types.MANAGER_SEARCH_USER_SUCCESS:
            return {
                ...state,
                managerSearchLoad: state.managerSearchLoad = false,
                managerIsError: state.managerIsError = false,
                managerSearchUserResult: state.managerSearchUserResult = action.payload.userResult
            }
        case types.MANAGER_SEARCH_USER_FAILURE:
            return {
                ...state,
                managerSearchLoad: state.managerSearchLoad = false,
                managerIsError: state.managerIsError = true,
                managerSearchUserResult: {},
                managerError_code: state.managerError_code = "404",
                managerError: state.managerError = action.payload.managerError
            }
        // -----------------------------------------------
        // ユーザー削除
        // -----------------------------------------------
        case types.MANAGER_TOGGLE_USER_DELETE:
            return {
                ...state,
                managerDeleteUserView: !state.managerDeleteUserView,
                managerActiveId: state.managerActiveId = action.payload.accountId
            }
        case types.MANAGER_DELETE_USER_LOADING:
            return {
                ...state,
                managerDeleteLoading: state.managerDeleteLoading = true,
                managerDeleteError: state.managerDeleteError = {},
            }
        case types.MANAGER_DELETE_USER_SUCCESS:
            return {
                ...state,
                managerDeleteLoading: state.managerDeleteLoading = false,
                managerDeleteError: state.managerDeleteError = {},
                managerActiveId: state.managerActiveId = null,
                managerDeleteUserView: state.managerDeleteUserView = false
            }
        case types.MANAGER_DELETE_USER_FAILURE:
            return {
                ...state,
                managerDeleteError: state.managerDeleteError = action.payload.error,
                managerDeleteLoading: state.managerDeleteLoading = false,
            }
        default:
            return state;
    }
}
