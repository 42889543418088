import React from 'react';
import {Form} from 'react-final-form';
import {
    Box,
    Button,
    Grid,
    Modal,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {useAuth0} from "@auth0/auth0-react";
import {empty} from "../../../../../state/utils/Common";
import {addressValidation} from "../../../../../state/ducks/user/service_contracts/validation";
import Loading from "../../../../atoms/Loading";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";

/**
 * コンポーネントスティール
 * @returns {{transform: string, top: string, left: string, width: number}}
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        width: 705,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * 利用契約 - 連絡先｜担当者情報の住所変更モーダル
 *
 * @param {Object} contactAddresses - 住所一覧
 * @param {string|null} activeAddress - 選択されている住所
 * @param {*} serviceContractContactAddressSelect - 住所一覧の取得イベント
 * @param {string} title - モダールのタイトル
 * @param {boolean} isOpen - モーダルの表示・非表示状態
 * @param {*} setClose - モーダル表示のアクション
 * @param {boolean} load - 最初モーダルのロード状態
 * @param {boolean} addressEditUpdateLoading - 住所更新のロード状態
 * @param {*} updateServiceContractContactAddress - 住所更新のアクション
 * @param {Object} contactData - 連絡先の情報
 * @param {string} serviceContractID - 利用契約ID
 * @param managerProfile - ローグインユーザーの情報
 * @param addressEditError - 住所更新エラー
 * @param contactKey - 請求担当者・アカウント担当者のキー
 * @param t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const AddressChangeModal = (
    {
        contactAddresses,
        activeAddress,
        serviceContractContactAddressSelect,
        title,
        isOpen,
        setClose,
        load,

        addressEditUpdateLoading,
        updateServiceContractContactAddress,

        contactData,
        serviceContractID,
        managerProfile,
        addressEditError,
        contactKey,

        t
    }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // グローバルスティール
    const globalClass = globalTheme._default();
    // auth0 アクセストークンの取得リクエスト
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Modal open={isOpen}
               onClose={() => setClose({})}
               disableBackdropClick
               className={addressEditUpdateLoading && globalClass.loading}
        >
            <div className={globalClass.modal} style={{...modalStyle, width: 705}}>
                <Typography variant="h3" style={{'paddingBottom': 30}} id="simple-modal-title">{title}</Typography>
                {/* Load Modal */}
                {load === true ?
                    <Box spacing={3}>
                        <Loading/>
                    </Box>
                    :
                    <Form
                        onSubmit={e => {
                            console.log(e);
                        }}
                        validate={e => {
                            return translate(t, Validation({...e}, addressValidation))
                        }}
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container alignItems="flex-start" spacing={3}>
                                    <TableContainer style={{maxHeight: "400px"}}>
                                        <Table size="small" aria-label="simple table">
                                            <TableHead style={{backgroundColor: "#F2F2F2"}}>
                                                <TableRow>
                                                    <TableCell> </TableCell>
                                                    <TableCell><strong>{t('service_contracts.detail.address.post_code_name')}</strong></TableCell>
                                                    <TableCell><strong>{t('service_contracts.detail.address.address')}</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {!empty(contactKey) && contactKey === "sold_to_contact" &&
                                                <TableRow data-testid="no_address" onClick={
                                                    () => {
                                                        serviceContractContactAddressSelect("0")
                                                    }
                                                } style={{cursor: "pointer"}}>
                                                    <TableCell component="th" scope="row">
                                                        <Radio
                                                            color={"primary"}
                                                            checked={empty(activeAddress)}
                                                            value="0"
                                                            name={"client_addresses"}
                                                        />
                                                    </TableCell>
                                                    <TableCell/>
                                                    <TableCell>
                                                        <Typography variant={"body1"}>
                                                            {t('service_contracts.detail.address.no_selection')}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                }
                                                {
                                                    !empty(contactAddresses) &&
                                                    !empty(contactAddresses.addresses) &&
                                                    contactAddresses.addresses.map((address) => (
                                                        <TableRow key={address.id} data-testid="row" onClick={
                                                            () => {
                                                                serviceContractContactAddressSelect(address.id.toString())
                                                            }
                                                        } style={{cursor: "pointer"}}>
                                                            <TableCell component="th" scope="row" style={{
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px"
                                                            }}>
                                                                <Radio
                                                                    color={"primary"}
                                                                    checked={activeAddress === address.id.toString()}
                                                                    value={address.id.toString()}
                                                                    name={"client_addresses"}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                style={{paddingTop: "3px", paddingBottom: "3px"}}>
                                                                <Typography variant="body1">
                                                                    {t('address.post_code_sign')}{address.postal_code}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{paddingTop: "3px", paddingBottom: "3px"}}>
                                                                <Typography variant="body1">
                                                                    {address.state}{address.city}{address.address}{address.name}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Grid item xs={12}>
                                        <Grid>
                                            <Typography className={globalClass.error}>
                                                {t(addressEditError?.translation_error_key)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container spacing={3} justify="flex-end">
                                        <Grid item>
                                            <Button
                                                type="button"
                                                variant="contained"
                                                color={"secondary"}
                                                disabled={addressEditUpdateLoading}
                                                onClick={() => setClose({})}
                                            >
                                                {t('actions.cancel')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="button"
                                                onClick={() => {
                                                    (async () => {
                                                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                                            () => {
                                                                return getAccessTokenSilently();
                                                            },
                                                        );
                                                        updateServiceContractContactAddress(contactData.uuid, serviceContractID, managerProfile?.account?.uuid, access_token, activeAddress)
                                                    })()
                                                }}
                                                disabled={submitting || (activeAddress !== "0" && empty(activeAddress))}
                                            >
                                                {addressEditUpdateLoading ? t('actions.sending') : t('actions.change')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />
                }
            </div>
        </Modal>
    )
};

export default AddressChangeModal;
