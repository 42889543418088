import {Box, Grid, Typography} from "@material-ui/core";
import ExpandDropDown from "../../../../atoms/ExpandDropDown";
import React from "react";

export const UsersListRow = props => {
    return (
        <Grid container key={props.data.users[props.index].uuid} style={{
            ...props.style,
            paddingLeft: 21,
            paddingTop: 13,
            paddingBottom: 13,
            paddingRight: 29,
            borderBottom: "1px #CFCFCF solid"
        }}>
            <Grid item xs={3}>
                <Box
                    alignItems="center"
                    display="flex"
                >
                    <Typography
                        color="textPrimary"
                        variant="body1"
                    >
                        {props.data.users[props.index].last_name} {props.data.users[props.index].first_name}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                >
                    {props.data.users[props.index].email}
                </Typography>
            </Grid>
            <Grid item xs={3} style={{marginTop: -5}}>
                <Box
                    justifyContent="flex-end"
                    display="flex"
                >
                    {/**
                     * 削除ユーザー, 編集ユーザーをドロップダウンアトムコンポーネントに追加します。
                     **/}
                    <ExpandDropDown
                        menuItems={props.data.users.length < 2 ?
                            [
                                {
                                    name: props.data.t('actions.edit'),
                                    event: () => props.data.handleEditClick(props.data.users[props.index])
                                }
                            ]
                            :
                            [
                                {
                                    name: props.data.t('actions.edit'),
                                    event: () => props.data.handleEditClick(props.data.users[props.index])
                                },
                                {
                                    name: props.data.t('actions.delete'),
                                    event: () => props.data.handleDeleteClick(props.data.users[props.index])
                                }
                            ]
                        }/>
                </Box>
            </Grid>
        </Grid>
    )
}
