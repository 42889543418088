import {mainReducer as root, rootState, rootWatcherSagas} from "./root";

import {userListState, userReducer as user, userWatcherSagas} from './user/users';
import {serviceContractReducer as serviceContract, serviceContractState, serviceContractWatcherSagas} from './user/service_contracts'

import {managerUserListState, managerUserReducer as managerUser, managerUserWatcherSagas} from "./manager/managerUsers";
import {
    managerAccountListState,
    managerAccountReducer as managerAccount,
    managerAccountWatcherSagas
} from "./manager/managerAccounts";

/***
 * ///////////////////////////////////////////////
 * REDUCKSメインファイル 🦆
 * ---------------------------
 *
 * これは、reduxの子グループを束ねるreducksのメインファイルです。
 * これで、ステイツ、レデューサー、レドゥーサガが結ばれる。
 *
 * reducksの詳細と、新しいreduxグループの追加については、以下のリンクをご参照ください。
 * reducksのテンプレート化の例 :  https://github.com/alexnm/re-ducks#enter-re-ducks
 * //////////////////////////////////////////////
 */
import {all, call, spawn} from 'redux-saga/effects';

/**
 * すべての子のreduxステートを束ねる
 */
export const StoreState = {
    root: rootState,
    user: userListState,
    serviceContract: serviceContractState,

    managerUser: managerUserListState,
    managerAccount: managerAccountListState,
};

/**
 * すべてのレデューサーイベントを束ねる
 */
export const reducers = {
    user,
    serviceContract,

    managerUser,
    managerAccount,
    root
};

/**
 * すべてのサガ・ウォッチャーを束ね、束ねられたウォッチャーの呼び出しに成功した場合、グローバル・キャッチを設定します。
 */
export function* rootSaga() {
    const watchers = [
        ...userWatcherSagas,
        ...managerAccountWatcherSagas,
        ...rootWatcherSagas,
        ...managerUserWatcherSagas,
        ...serviceContractWatcherSagas
    ];

    yield all(
        watchers.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }),
        ),
    );
}
