/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /managerAccounts 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */

/**
 * アカウント作成時の妥当性確認
 */
export const managerAccountStoreValidation = {
    sf_account: {
        presence: true
    },
    notes: {
        presence: false,
        length: {maximum: 80}
    },
    sf_account_user: {
        presence: true,
    }
}

/**
 * アカウント編集の妥当性確認
 */
export const managerEditStoreValidation = {
    name: {
        presence: true,
        length: {maximum: 255}
    },
    note: {
        presence: false,
        length: {maximum: 80}
    },
}

