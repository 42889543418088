/***
 * /////////////////////////////////////////////////////////////////
 * CONSTANTS (定数）
 * ----------------------------
 *
 * TYPEではないmanagerAccountsに関連するすべての定数について
 * 例を挙げると
 * API エンドポイント
 * キーワード検索の下限値
 * /////////////////////////////////////////////////////////////////
 *
 */

export const MANAGER_USERS_API_PATH = '/manager/users/';
export const MANAGER_MIN_CHAR_REQUIRED_SEARCH_INPUT = 3;
