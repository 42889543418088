import React from 'react';
import {Field, Form} from 'react-final-form';
import {Button, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import FinalFormTextField from "../../../../atoms/FinalFormTextField";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {managerEditStoreValidation} from "../../../../../state/ducks/manager/managerAccounts/validation";
import FinalFormTextArea from "../../../../atoms/FinalFormTextArea";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {managerAccountForms} from "../../../../../state/ducks/manager/managerAccounts";

/**
 * コンポーネントスタイルの編集
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * 管理者側のアカウントの編集モダール
 *
 * @param {boolean} isOpen - モーダルは開いていますか？フラグ
 * @param {*} setClose - モーダルクローズイベント
 * @param {*} editEvent - 編集イベント
 * @param {Object} activeAccount - 編集されているアカウント
 * @param {boolean} loading - 処理の更新フラグ
 * @param {Object} error - APIエラー
 * @param {*} t - 翻訳
 * @returns {JSX.Element}
 * @constructor
 */
const ManagerAccountEdit = ({
                                isOpen,
                                setClose,
                                editEvent,
                                activeAccount,
                                loading,
                                error,
                                t
                            }) => {
    // グローバルクラスの読み込み
    const [modalStyle] = React.useState(getModalStyle);
    const classes = globalTheme._default();
    // auth0からアクセストークンを取得
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Modal open={isOpen}
               onClose={setClose}
               disableBackdropClick
               className={loading && classes.loading}
        >
            <div className={classes.modal} style={modalStyle}>
                <Typography variant="h3" style={{'paddingBottom': 30}}
                            id="simple-modal-title">{t('accounts.edit.title')}</Typography>
                <Form
                    onSubmit={e => {
                        e = mergeWithDefaultForm(e, managerAccountForms.editForm)
                        // ダブルクリック対策
                        if (!loading) {
                            (async () => {
                                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                    () => {
                                        return getAccessTokenSilently();
                                    },
                                );
                                editEvent(activeAccount, e, access_token);
                            })()
                        }
                    }}
                    // フォームバリデーション
                    validate={e => {
                        return translate(t, Validation({...e}, managerEditStoreValidation))
                    }}
                    // コンポーネントの負荷に関する初期値
                    initialValues={{
                        name: !empty(activeAccount) ? activeAccount.name : "",
                        note: !empty(activeAccount) ? activeAccount.note : "",
                    }}
                    render={({handleSubmit, form, submitting, pristine, invalid, values}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        size={"small"}
                                        name="name"
                                        required={true}
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('accounts.edit.supplier_name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        name="note"
                                        component={FinalFormTextArea}
                                        type="text"
                                        label={t('accounts.edit.note')}
                                    />
                                </Grid>
                                {!empty(error) &&
                                <Grid item xs={12} style={{marginTop: 0}}>
                                    <Typography className={classes.error}>
                                        {t(error?.translation_error_key)}
                                    </Typography>
                                </Grid>
                                }
                                <Grid item container spacing={3} justify="flex-end">
                                    <Grid item style={{marginTop: 16}}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            disabled={loading}
                                            onClick={setClose}
                                        >
                                            {t('actions.cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item style={{marginTop: 16}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={submitting || invalid || pristine}
                                        >
                                            {loading ? t('actions.sending') : t('actions.change')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>)}
                />
            </div>
        </Modal>
    )
};

export default ManagerAccountEdit;
