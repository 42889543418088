/**
 * ///////////////////////////////////////////////
 * 共通ユーティリティファイル
 * ----------------------------------
 *
 *  アプリケーションで使用される基本ユーティリティー関数
 * ///////////////////////////////////////////////
 */

/**
 * 変数が "空 "であるかどうかをチェックします
 * 空 == "", {}, false, null, 'undefined', 0
 *
 * @param {*} mixedVar
 * @returns {boolean}
 */
export function empty(mixedVar) {
    let undef
    let key
    let i
    let len
    const emptyValues = [undef, null, false, 0, '', '0']
    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
            return true
        }
    }
    if (typeof mixedVar === 'object') {
        for (key in mixedVar) {
            if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
                return false
            }
        }
        return true
    }
    return false
}

/**
 * 現在のフォーム内容とフォームテンプレをマージする。
 * @param currentForm 送信したフォーム
 * @param defaultForm テンプレフォーム
 */
export function mergeWithDefaultForm(currentForm, defaultForm) {
    return {
        ...defaultForm,
        ...currentForm
    }
}

