import storeProvider from '../../StoreProvider'
import {accessTokenFailure} from "../ducks/root/actions";
/**
 * /////////////////////////////////////////////
 * AUTH0 ユーティリティー
 * -------------------------
 *
 * Auth0のユーティリティー関数
 * /////////////////////////////////////////////
 */

/**
 *
 * @param {*} accessTokenFunction
 * @returns {Promise<*>}
 */
const checkAndUpdateAccessToken = async (accessTokenFunction) => {
    return accessTokenFunction().catch((t) => {
        storeProvider.getStore().dispatch(accessTokenFailure("actions.access_token_message"))
    });
}

const Auth0Utils = {
    checkAndUpdateAccessToken
}
export default Auth0Utils;
