import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import NavBar from './NavBar/NavBar';
import TopBar from './TopBar';
import Contents from "../../components/user/Contents";
import {empty} from "../../../state/utils/Common";
import {useAuth0} from "@auth0/auth0-react";
import Auth0Utils from "../../../state/utils/Auth0Utils";
import Footer from "./Footer";

/**
 * コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 92,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 220
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

/**
 * 通常ユーザーユーザー側のダッシュボードレイアウト
 *
 * @param getProfile
 * @param managerProfile
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardLayout = ({getProfile, managerProfile}) => {
    // コンポーネントスタイルの取得
    const classes = useStyles();
    // auth0アクセストークンの取得
    const {user, getAccessTokenSilently} = useAuth0();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    // 現在のユーザープロファイルの取得
    if (!empty(user) && empty(managerProfile)) {
        (async () => {
            const accessToken = await Auth0Utils.checkAndUpdateAccessToken(
                () => {
                    return getAccessTokenSilently();
                },
            );
            getProfile(user.sub, accessToken);
        })();
    }
    return (
        <div className={classes.root}>
            <TopBar managerProfile={managerProfile} onMobileNavOpen={() => setMobileNavOpen(true)}/>
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Contents/>
                        <Footer/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
