import {makeStyles} from "@material-ui/core";

/**
 *   オブジェクト型クラスのグローバルテーマ化はここで行われ、これらのスタイルはどのテーマでも使用されます。
 */

/**
 * グローバル・フォーム・スタイリング
 */
const defaultFormStyle = makeStyles((theme) => ({
        chip: {
            backgroundColor: theme.palette.background.required,
            marginLeft: "5px",
            borderRadius: "5px",
            fontWeight: 500,
            color: "#FFFFFF"
        },
        textfield: {
            marginTop: "5px"
        },
        disabledInput: {
            backgroundColor: "#EEEEEE",
            color: "#8F8F8F",
            fontWeight: "bolder!important"
        },
        warningButton: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.secondary.main
        }
    }),
    {index: 1}
);

/**
 * カードテーマ
 */
const defaultCards = makeStyles((theme) => ({
    headers: {
        borderBottomColor: "#F2F2F2",
        borderBottomWidth: 1,
        paddingTop: 11,
        paddingBottom: 11,
        borderBottomStyle: "solid",
        marginBottom: 5
    }
}));

/**
 * ディフォルトテーマオブジェクト
 * @private
 */
export const _default = makeStyles((theme) => ({
    link: {
        color: "#0D66D0",
        cursor: "pointer"
    },
    modal: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 4, 3),
    },
    footer: {
        position: "fixed",
        left: 64,
        [theme.breakpoints.down('lg')]: {
            left: 0
        },
        backgroundColor: theme.palette.background.dark,
        width: "100%",
        bottom: 0
    },
    a: {
        textDecoration: "underline",
        color: "#000000",
        letterSpacing: "0",
    },
    loading: {
        cursor: "wait"
    },
    error: {
        color: "#f44336"
    },
    list: {
        height: "calc(82vh - 170px)"
    }
}));


const globalTheme = {
    _default,
    defaultFormStyle,
    defaultCards
}
export default globalTheme;
