import React from 'react'
import {IconButton, makeStyles, Menu} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExpandDropDownItems from "./ExpandDropDownItems";

/**
 * ドロップダウンメニューのスタイル
 */

const useStyles = makeStyles((theme) => ({
    MenuButton: {
        padding: 5,
        '&:hover': {
            backgroundColor: theme.palette.background.active
        },
    },
}))

/**
 * ドロップダウンメニュー
 * @param {[*]} menuItems
 */
const ExpandDropDown = ({menuItems}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <IconButton color={"primary"} component={"span"} onClick={handleClick} className={classes.MenuButton}>
                <MoreHorizIcon aria-controls="simple-menu" aria-haspopup="true"/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems.map((value, index) => (
                    <ExpandDropDownItems key={value.name + index.toString()} onClickEvent={value.event} handleClose={handleClose}
                                                itemName={value.name}/>
                ))}
            </Menu>
        </div>
    );
}
export default ExpandDropDown;
