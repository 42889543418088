import React from 'react';
import {NavLink as RouterLink, useLocation} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Button, ListItem, makeStyles} from '@material-ui/core';

/**
 * コンポーネントスタイル
 */
const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '8px 8px',
        textTransform: 'none',
        width: '100%',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.active,
            '& $title': {
                fontWeight: theme.typography.fontWeightHeavy
            },
            '& $icon': {
                color: theme.palette.primary.main
            }
        }
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto',
        fontSize: '13px'
    },
    active: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.active,
        '& $title': {
            fontWeight: theme.typography.fontWeightHeavy
        },
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));
/**
 * シングルナビアイテムコンポーネント
 */
const NavItem = ({
                     className,
                     href,
                     icon: Icon,
                     activeOverride,
                     title,
                     ...rest
                 }) => {
    const classes = useStyles();
    const location = useLocation();
    return (
        <ListItem
            className={clsx(classes.item, className)}
            disableGutters
            {...rest}
        >
            {activeOverride && location.pathname.includes(activeOverride) ?
                <Button
                    className={classes.active}
                    component={RouterLink}
                    to={href}
                >
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                        />
                    )}
                    <span className={classes.title}>{title}</span>
                </Button>
                :

                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    component={RouterLink}
                    to={href}>
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                        />
                    )}
                    <span className={classes.title}>{title}</span>
                </Button>
            }
        </ListItem>
    );
};

NavItem.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string
};

export default NavItem;
