import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Box, Button, Card, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import {empty} from "../../../state/utils/Common";
import { Trans } from 'react-i18next'

/**
 * エラーレイアウト (エラーレイアウト（空テンプレートを表示)
 *
 * @param {string} title - タイトル
 * @param {string} message - エラー内容
 * @param {*} t - 翻訳ファイル
 * @param {Object} error - エラー
 * @param resetErrorBoundary
 * @param error_code
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorLayout = ({title, message, t, error, resetErrorBoundary, error_code}) => {
    const {logout} = useAuth0();
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{minHeight: '100vh'}}
        >
            <Grid xs={12} md={6}>
                <Card>
                    <CardHeader title={title}/>
                    <CardContent>
                        <Grid xs={12}>
                            <Typography style={{whiteSpace: "pre-line"}}>
                                <Trans i18nKey={'error.description'} t={t} contact_mail={process.env.REACT_APP_EBIS_CONTACT_MAIL}>
                                    1 <a style={{color:"initial"}} href={"mailto:" + process.env.REACT_APP_EBIS_CONTACT_MAIL}>（{{contact_mail: process.env.REACT_APP_EBIS_CONTACT_MAIL}}）</a>
                                    2
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Box mt={2}>
                                <Typography style={{whiteSpace: "pre-line"}}>
                                    <code>{!empty(message) ? message : error.toString()}:{error_code}</code>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            <Button style={{float: "right", marginBottom: 15}}
                                    onClick={() => logout({returnTo: window.location.origin + "/sign_out"})}
                                    color={"primary"} variant={"outlined"}>
                                {t('actions.logout')}
                            </Button>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ErrorLayout;
