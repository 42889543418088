import {call, put} from 'redux-saga/effects';
import {error_raise, getProfileFailure, getProfileSuccess} from "./actions";
import {callManagerProfileAPI} from "./api";

/***
 * ////////////////
 * SAGA MIDDLEWARE (SAGAミドルウェア)
 * ---------------
 *
 * SAGAミドルウェアは、アクションとリデューサ間のアクションを解析し、アクションのTYPEに基づいてAPI関数を処理します。
 * すべてのSAGAミドルウェアは、<i>watchersSagas.js</i>から呼び出されます。
 * ////////////////
 */

/**
 * ユーザープロファイルの取得リクエスト
 * @param data
 */
export function* callManagerProfile(data) {
    try {
        const profile = yield call(callManagerProfileAPI, data.payload.auth0_id, data.payload.access_token);
        yield put(getProfileSuccess(profile.data));
    } catch (e) {
        yield put(getProfileFailure(e));
        if (e.http_code === "NETWORK_ERROR") {
            yield put(error_raise(e))
        }
    }
}
