import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// 利用契約一覧取得リクエスト
//-------------------------------------------------

/***
 * 利用契約一覧取得リクエスト
 * @param {string} access_token - auth0アクセストーケン
 * @param user_id - ユーザーアカウントID
 * @returns {number|string} {{payload: {access_token, user_id}, type: string}}
 */
export function loadServiceContractList(access_token, user_id) {
    return {
        type: types.REQUEST_SERVICE_CONTRACT,
        payload: {
            access_token: access_token,
            user_id: user_id
        }
    };
}

/***
 *利用契約一覧取得リクエスト(ロード中）
 * @returns {{type: string}}
 */
export function loadingServiceContractList() {
    return {
        type: types.REQUEST_SERVICE_CONTRACTS_LOADING
    }
}

/***
 * 利用契約一覧取得リクエスト(成功）
 * @param {Object} serviceContracts - 利用契約一覧データ
 * @returns {{payload: {serviceContracts}, type: string}}
 */
export function successServiceContractList(serviceContracts) {
    return {
        type: types.REQUEST_SERVICE_CONTRACTS_SUCCESS,
        payload: {
            serviceContracts: serviceContracts
        }
    }
}

/***
 * 利用契約一覧取得リクエスト(失敗）
 * @param {Object }error - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function failureServiceContractList(error) {
    return {
        type: types.REQUEST_SERVICE_CONTRACTS_FAILURE,
        payload: {
            error: error
        }
    }
}

//-------------------------------------------------
// 利用契約詳細取得リクエスト (/contacts)
// FIXME: 今、還元される？
//-------------------------------------------------

/***
 *
 * @param serviceContract
 * @returns {{payload: {serviceContract}, type: string}}
 */
export function extractServiceContractInformation(serviceContract) {
    return {
        type: types.EXTRACT_SERVICE_CONTRACT_DETAIL,
        payload: {
            serviceContract: serviceContract
        }
    }
}

//-------------------------------------------------
// 単一のサービス契約の読み込み（現在のデータがない場合)
//-------------------------------------------------
/***
 * 単一のサービス契約のリクエスト
 * @param access_token - auth0アクセストーケン
 * @param accountId - ユーザーアカウントID
 * @param serviceContractId - 利用契約ID
 * @returns {{payload: {access_token, accountId, serviceContractId}, type: string}}
 */
export function requestSingleServiceContract(access_token, accountId, serviceContractId) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT,
        payload: {
            access_token: access_token,
            accountId: accountId,
            serviceContractId: serviceContractId
        }
    }
}

/***
 * 単一のサービス契約のリクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadSingleServiceContract() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_LOADING
    }
}

/***
 * 単一のサービス契約のリクエスト (成功）
 * @param serviceContract - 利用契約ID
 * @returns {{payload: {activeServiceContract}, type: string}}
 */
export function singleServiceContractSuccess(serviceContract) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS,
        payload: {
            activeServiceContract: serviceContract
        }
    }
}

/***
 * 単一のサービス契約のリクエスト（失敗）
 * @param e - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function singleServiceContractFailed(e) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_FAILED,
        payload: {
            error: e
        }
    }
}


//-------------------------------------------------
// サービス契約の詳細 連絡先イベントの編集
//-------------------------------------------------

/***
 * アカウント担当者の変更モーダル開閉
 * @returns {{type: string}}
 */
export function toggleSoldToModal() {
    return {
        type: types.TOGGLE_SOLD_TO_MODAL,
    }
}

/***
 * アカウント担当者の変更モーダル開閉
 * @returns {{type: string}}
 */
export function toggleBilledToModal() {
    return {
        type: types.TOGGLE_BILLED_TO_MODAL,
    }
}

//-------------------------------------------------
// サービス契約の詳細 連絡先取得リクエスト
//-------------------------------------------------

/***
 * サービス契約の詳細 連絡先取得リクエスト
 * @param toggleEvent - モーダル開閉イベント
 * @param contactId - 連絡先ID・キー
 * @param serviceContractId  - 利用契約ID
 * @param access_token - auth0アクセストーケン
 * @param accountId - ユーザーアカウントID
 * @returns {{payload: {accountId, contactId, accessToken, toggleEvent, serviceContractId}, type: string}}
 */
export function loadServiceContractContact(toggleEvent, contactId, serviceContractId, accessToken, accountId) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT,
        payload: {
            toggleEvent: toggleEvent,
            contactId: contactId,
            serviceContractId: serviceContractId,
            accessToken: accessToken,
            accountId: accountId
        }
    }
}

/***
 * サービス契約の詳細 連絡先取得リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadServiceContractContactLoading() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_LOADING
    }
}

/***
 * サービス契約の詳細 連絡先取得リクエスト（成功）
 * @param activeContact - 連絡先ID
 * @returns {{payload: {activeContact}, type: string}}
 */
export function loadServiceContractContactSuccess(activeContact) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_SUCCESS,
        payload: {
            activeContact: activeContact
        }
    }
}

/***
 * サービス契約の詳細 連絡先取得リクエスト   (失敗)
 * @param error - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function loadServiceContractContactFailed(error) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_FAILED,
        payload: {
            error: error
        }
    }
}

//-------------------------------------------------
// サービス契約の詳細 連絡先住所取得リクエスト
//-------------------------------------------------

/***
 * サービス契約の詳細 連絡先住所取得リクエスト
 * @param toggleEvent - 開閉モーダルエヴェント
 * @param contactId - 連絡先ID
 * @param serviceContractId  - 利用契約ID
 * @param access_token - auth0アクセストーケン
 * @param accountId - ユーザーアカウントID
 * @returns {{payload: {accountId, contactId, accessToken, toggleEvent, serviceContractId}, type: string}}
 */
export function loadServiceContractContactAddress(toggleEvent, contactId, serviceContractId, accessToken, accountId) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS,
        payload: {
            toggleEvent: toggleEvent,
            contactId: contactId,
            serviceContractId: serviceContractId,
            accessToken: accessToken,
            accountId: accountId
        }
    }
}

/***
 * サービス契約の詳細 連絡先住所取得リクエスト（ロード中）
 * @returns {{type: string}}
 */
export function loadServiceContractContactAddressLoading() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING,
    }
}

/***
 *  サービス契約の詳細 連絡先住所取得リクエスト（成功）
 * @param addressList - 住所一覧
 * @returns {{payload: {addressList}, type: string}}
 */
export function loadServiceContractContactAddressSuccess(addressList) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS,
        payload: {
            addressList: addressList
        }
    }
}

/***
 *  サービス契約の詳細 連絡先住所取得リクエスト(失敗)
 * @param error - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function loadServiceContractContactAddressFailed(error) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED,
        payload: {
            error: error
        }
    }
}

/***
 *  アカウント担当者の住所モーダル表示の開閉
 * @returns {{type: string}}
 */
export function loadServiceContractContactAddressSoldToToggle() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_SOLD_TO,
    }
}

/***
 * 請求担当者の住所モーダル表示の開閉
 * @returns {{type: string}}
 */
export function loadServiceContractContactAddressBilledToToggle() {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_BILLED_TO,
    }
}

//-------------------------------------------------
// サービス契約は、サービス契約のアドレスを選択する
//-------------------------------------------------

/***
 * サービス契約のアドレスを選択する
 * @param address - 住所一覧
 * @returns {{payload: {activeAddress}, type: string}}
 */
export function serviceContractContactAddressSelect(address) {
    return {
        type: types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SELECT,
        payload: {
            activeAddress: address
        }
    }
}


//-------------------------------------------------
// サービス契約詳細 連絡先編集リクエスト
//-------------------------------------------------
/***
 * 連絡先編集リクエスト
 * @param contactId - 連絡先ID
 * @param serviceContractId  - 利用契約ID
 * @param accountId - ユーザーアカウントID
 * @param serviceContractData - 利用契約データ
 * @param accessToken access_token - auth0アクセストーケン

 * @returns {{payload: {accountId, contactId, accessToken, serviceContractData, serviceContractId}, type: string}}
 */
export function updateServiceContractContact(contactId, serviceContractId, accountId, serviceContractData, accessToken) {
    return {
        type: types.UPDATE_SERVICE_CONTRACT_CONTACT_REQUEST,
        payload: {
            contactId: contactId,
            serviceContractId: serviceContractId,
            serviceContractData: serviceContractData,
            accountId: accountId,
            accessToken: accessToken,
        }
    }
}

/***
 * 連絡先編集リクエスト(ロード中）
 * @returns {{type: string}}
 */
export function updateServiceContractLoading() {
    return {
        type: types.UPDATE_SERVICE_CONTRACT_CONTACT_LOADING
    }
}

/***
 * 連絡先編集リクエスト(成功）
 * @returns {{type: string}}
 */
export function updateServiceContractSuccess() {
    return {
        type: types.UPDATE_SERVICE_CONTRACT_CONTACT_SUCCESS
    }
}

/***
 * 連絡先編集リクエスト(失敗）
 * @param error
 * @returns {{payload: {error}, type: string}}
 */
export function updateServiceContractFailed(error) {
    return {
        type: types.UPDATE_SERVICE_CONTRACT_CONTACT_FAILED,
        payload: {
            error: error
        }
    }
}

//-------------------------------------------------
// サービス契約の詳細 連絡先住所の編集リクエスト
//-------------------------------------------------
/***
 * 連絡先住所の編集リクエスト
 * @param contactId - 連絡先ID
 * @param serviceContractId  - 利用契約ID
 * @param accountId - ユーザーアカウントID
 * @param accessToken - auth0 アクセストーケン
 * @param addressData - 住所データ
 * @returns {{payload: {accountId, contactId, accessToken, addressData: {id: (number|*)}, serviceContractId}, type: string}}
 */
export function updateServiceContractContactAddress(contactId, serviceContractId, accountId, accessToken, addressData) {
    return {
        type: types.UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_REQUEST,
        payload: {
            contactId: contactId,
            serviceContractId: serviceContractId,
            addressData:
                {
                    id: (typeof addressData === "string" ? parseInt(addressData) : addressData)
                },
            accountId: accountId,
            accessToken: accessToken,
        }
    }
}

/***
 * 連絡先住所の編集リクエスト(ロード中）
 * @returns {{type: string}}
 */
export function updateServiceContractContactAddressLoading() {
    return {
        type: types.UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING
    }
}

/***
 * 連絡先住所の編集リクエスト(成功）
 * @returns {{type: string}}
 */
export function updateServiceContractContactAddressSuccess() {
    return {
        type: types.UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS
    }
}

/***
 * 連絡先住所の編集リクエスト(失敗）
 * @param error - エラー内容
 * @returns {{payload: {error}, type: string}}
 */
export function updateServiceContractContactAddressFailed(error) {
    return {
        type: types.UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED,
        payload: {
            error: error
        }
    }
}

const actions = {
    loadServiceContractList,
    loadingServiceContractList,
    successServiceContractList,
    failureServiceContractList,

    extractServiceContractInformation,
    requestSingleServiceContract,
    loadSingleServiceContract,
    singleServiceContractSuccess,
    singleServiceContractFailed,

    loadServiceContractContact,
    loadServiceContractContactLoading,
    loadServiceContractContactSuccess,
    loadServiceContractContactFailed,

    loadServiceContractContactAddress,
    loadServiceContractContactAddressLoading,
    loadServiceContractContactAddressSuccess,
    loadServiceContractContactAddressFailed,
    loadServiceContractContactAddressSoldToToggle,
    loadServiceContractContactAddressBilledToToggle,
    serviceContractContactAddressSelect,

    toggleSoldToModal,
    toggleBilledToModal,
    updateServiceContractContact,
    updateServiceContractLoading,
    updateServiceContractSuccess,
    updateServiceContractFailed,

    updateServiceContractContactAddress,
    updateServiceContractContactAddressLoading,
    updateServiceContractContactAddressSuccess,
    updateServiceContractContactAddressFailed
};
export default actions;
