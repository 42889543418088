/***
 * /////////////////////////////////////////
 * フォーム妥当性確認  (Using the validate.js library)
 * -------------------------
 *
 * /managerAccounts 用のフォームバリデータです。
 *
 * Validate.js と /util/ValidateUtil.js を使ってバリデーションを設定します。
 * バリデーションルールについてはこちらをご覧ください。
 * https://validatejs.org/#validators
 *
 * すべてのフォーム検証属性はここに格納され、ビューコンポーネントから直接呼び出されます。
 * //////////////////////////////////////////
 */

/**
 * ユーザー更新・追加フォーム妥当性確認
 */
export const userStoreValidation = {
    last_name: {
        presence: true,
        length: {maximum: 80}
    },
    first_name: {
        presence: true,
        length: {maximum: 40}
    },
    email: {
        presence: true,
        email: true,
        length: {maximum: 80}
    }
}
