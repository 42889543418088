import axios from "axios";
import storeProvider from '../../StoreProvider'
import {error_raise} from "../ducks/root/actions";
import {empty} from "./Common";
/***
 * ///////////////////////////////////////////////
 * APIユーティリティー  (Axiosを使用)
 * -------------------------------
 *
 * APIを呼び出すためのユーティリティクラス
 * authorizationやcontent-typeなどのデフォルトヘッダーのプリセット
 *
 * RESTのデフォルト(GET, POST, DELETE, PUT)はここで呼び出されます。
 * @dependencies https://github.com/axios/axios
 * //////////////////////////////////////////////
 */

/**
 * reactフレームワークの外で環境変数を呼び出す
 */
require('dotenv').config()

/**
 * プリリクエストの設定はこちら
 */
axios.interceptors.request.use(async function (config) {
    return config;
});

const systemErrorCodes = [403, 500]
const systemErrorMessage = ["E403_ILLEGAL_HEADERS", "E403_ILLEGAL_OPERATOR", "E500_AUTHOLIZATION_ERROR"]

/**
 * システムエラーチェック
 * @param response
 * @returns {boolean}
 */
function isSystemError(response) {
    return systemErrorCodes.includes(response.status) && systemErrorMessage.includes(response.data?.code);
}

const splitErrorMessage = ["E409_USER_ALREADY_EXISTS"]

/**
 * ログインユーザーの種類に応じてエラーメッセージを分けるチェック
 * @param response
 * @returns {boolean}
 */
function isGroupSpecificError(response) {
    return splitErrorMessage.includes(response.data?.code)
}


/**
 * レスポンス デフォルトのインターセプター
 */
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    let userType = "";
    if (error.response) {
        // システムエラーのリスポンスがあった場合
        if (isSystemError(error.response)) {
            const state = storeProvider.getStore().getState();
            if (!state.root.isError) {
                storeProvider.getStore().dispatch(error_raise({
                    title: error.response.status,
                    translation_error_key: `error_codes.${error.response.status}.${error.response.data?.code}`
                }))
            }
        }
        // ログインユーザーの種類に応じてエラーメッセージを分ける場合
        if(isGroupSpecificError(error.response)) {
            const state = storeProvider.getStore().getState();
            userType = state.root.managerProfile?.is_user === false ? "ADMIN." : "USER.";
        }
        return Promise.reject({
            message: error.response.data,
            http_code: error.response.status,
            response_headers: error.response.headers,
            translation_error_key: `error_codes.${error.response.status}.${userType}${error.response.data?.code}`
        })
    } else if (error.request) {
        return Promise.reject({
            message: error.request,
            title: "ERROR_CODE: NETWORK_ERROR",
            http_code: "NETWORK_ERROR",
            response_headers: null,
            request: error.request,
            translation_error_key: `error_codes.OFFLINE.NETWORK_ERROR`
        })
    }
    return Promise.reject({
        message: error.message,
        title: "ERROR_CODE: UNKNOWN",
        http_code: "UNKNOWN",
        response_headers: null,
        translation_error_key: `error_codes.UNKNOWN.INTERRUPTED`
    })
})

export default class ApiUtils {
    /**
     * constructor
     * @param {string|null} accessToken auth0 アクセストークン
     * @param {number|null} timeout タイムアウト
     * @param {string|null} baseurl カスタムURL
     */
    constructor(accessToken, timeout = null, baseurl = null) {
        axios.defaults.baseURL = baseurl || process.env.REACT_APP_MANAGEMENT_API_DOMAIN;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.post['Accept'] = 'application/json';
        if(!empty(process.env.REACT_APP_MOCK_MANAGEMENT_API_DOMAIN)) {
            const state = storeProvider.getStore().getState();
            const debug_response = state.root.debug_response
            if(!empty(debug_response)) {
                axios.defaults.baseURL = process.env.REACT_APP_MOCK_MANAGEMENT_API_DOMAIN
                axios.defaults.headers.common['Prefer'] = 'code=' + debug_response
            }
        }

        if (accessToken !== null) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
        }
        axios.defaults.timeout = timeout || process.env.REACT_APP_MANGEMENT_API_TIMEOUT;
    }

    /**
     * GET リクエスト
     * @param uri API アクセスポイント
     * @param parameters Get クエリーパラメーター
     * @returns {AxiosPromise} (API|Network) リクエスト
     */
    get(uri, parameters) {
        return axios({
            method: 'GET',
            url: uri,
            params: {
                ...parameters,
            },
        });
    }

    /**
     * POST リクエスト
     * @param {string} uri API アクセスポイント
     * @param {Object} body Post データ
     * @returns {AxiosPromise} (API|Network) リスポンス
     */
    post(uri, body) {
        return axios({
            method: 'POST',
            url: uri,
            data: JSON.stringify(body)
        });
    }

    /**
     * DELETE リクエスト
     * @param {string} uri API アクセスポイント
     * @param {string} id ID
     * @returns {AxiosPromise} (API|Network) リスポンス
     */
    delete(uri, id) {
        return axios({
            method: 'DELETE',
            url: uri + id,
        });
    }

    /**
     * UPDATE リクエスト
     * @param uri API アクセスポイント
     * @param id Unique ID
     * @param body 更新データ
     * @returns {AxiosPromise} (API|Network) リスポンス
     */
    put(uri, id, body) {
        return axios({
            method: 'PUT',
            url: uri + id,
            data: body
        });
    }
}

export {axios};
