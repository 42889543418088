import types from "./types";

/***
 * ////////////////////////////////////////////////
 * ACTIONS (アクション)
 * ------------------
 *
 * コンポーネントやコンテナから要求・呼び出しされるアクション
 * アクションには、REDUCER FILEまたはSAGAミドルウェアにイベントをブロードキャストするためのTYPEキーが必要で、REDUCERまたはSAGAミドルウェアは以下を解析します。
 * ////////////////////////////////////////////////
 */

//-------------------------------------------------
// エラーイベント
//-------------------------------------------------

export function error_raise(error) {
    return {
        type: types.ERROR_OCCURRED_EVENT,
        payload: {
            error: error
        }
    }
}

export function error_confirm() {
    return {
        type: types.ERROR_CONFIRM_EVENT,
    }
}

//-------------------------------------------------
// プロファイル取得リクエスト
//-------------------------------------------------
/**
 * プロファイル取得リクエスト
 * @param {string} auth0_user_id - auth0 user id
 * @param {string} accessToken - auth0 access token
 */
export function getProfile(auth0_user_id, accessToken) {
    return {
        type: types.GET_MANAGER_PROFILE,
        payload: {
            auth0_id: auth0_user_id,
            access_token: accessToken
        }
    }
}

/**
 * プロファイル取得リクエスト（成功）
 * @param {Object} user_profile - returned user profile
 */
export function getProfileSuccess(user_profile) {
    return {
        type: types.GET_MANAGER_PROFILE_SUCCESS,
        payload: {
            user_profile: user_profile
        }
    }
}

/**
 * プロファイル取得リクエスト（失敗）
 * @param {Object} e - error message
 */
export function getProfileFailure(e) {
    return {
        type: types.GET_MANAGER_PROFILE_FAILURE,
        payload: {
            error: e
        }
    }
}

/**
 * auth0アクセストークン取得失敗
 * @param error_message - エラー内容
 * @returns {{payload: {message}, type: string}}
 */
export function accessTokenFailure(error_message) {
    return {
        type: types.ACCESS_TOKEN_FAILURE,
        payload: {
            message: error_message
        }
    }
}

const actions = {
    error_raise,
    error_confirm,

    getProfile,
    getProfileSuccess,
    getProfileFailure,

    accessTokenFailure
};
export default actions;
