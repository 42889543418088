import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {managerCommonStyles} from "../../../../theme/manager/styles/common";
import {FixedSizeList} from "react-window";
import {UsersListRow} from "./UserListRow";
import AutoSizer from "react-virtualized-auto-sizer";
import globalTheme from "../../../../theme/globalTheme";

/**
 * コンポーネントスタイル
 *
 * @type {(props?: any) => ClassNameMap<"paper"|"root">}
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
/**
 * 管理者側のユーザー一覧
 *
 * @param {makeStyles} className - コンポーネントのスタイル
 * @param {Object} users - ロードされたユーザー
 * @param {number} managerUserList_CurrentPage - ページネーションの現在のページ
 * @param {managerUserChangePage} managerUserChangePage - ページアクションの変更
 * @param {managerViewUserDeleteToggle} managerViewDeleteToggle - 削除確認モーダルビューの動作をトグル
 * @param {managerViewRegisterToggle} managerViewRegisterToggle - トグル表示 レジスタ/エディットモーダル
 * @param {managerLoadingUserList} managerLoading - "一覧が読み込まれていますか？"状態
 * @param {*} t - 翻訳オブジェクト
 * @returns {JSX.Element}
 * @constructor
 */
const UsersList = ({
                       className,
                       users,
                       managerUserList_CurrentPage,
                       managerUserChangePage,
                       managerViewDeleteToggle,
                       managerViewRegisterToggle,
                       managerLoading,
                       t
                   }) => {
    const classes = useStyles();

    /**
     * ユーザーリストの編集・削除のイベント処理
     */
    function handleEditClick(id) {
        managerViewRegisterToggle(id)
    }

    function handleDeleteClick(id) {
        managerViewDeleteToggle(id)
    }

    const common = managerCommonStyles();
    const _globalTheme = globalTheme._default();
    return (
        <Card
            className={clsx(classes.root, className)}
        >
            <Box p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{fontWeight: 900}}
                        >
                            {t('users.list')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{'textAlign': 'right'}}
                        >
                            {t('general.list_amount', {amount: !empty(users) ? users.length : 0})}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
                <Grid container className={common.tableHeader}>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('users.list_headers.user_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('users.list_headers.mail_address')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>

                    </Grid>
                </Grid>
                {managerLoading === true ?
                    <Box className={_globalTheme.list} display="flex" alignItems="center" data-testid={"loading"}>
                    <Grid container
                          direction="row"
                          justify="center"
                          alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Loading/>
                        </Grid>
                    </Grid>
                    </Box>
                        : !empty(users) &&
                    <Box className={_globalTheme.list} data-testid={"has-users"}>
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                width={width}
                                height={height}
                                itemCount={users.length}
                                itemSize={45}
                                itemData={{
                                    users: users,
                                    t: t,
                                    handleEditClick: (id) => handleEditClick(id),
                                    handleDeleteClick: (id) => handleDeleteClick(id)
                                }}
                            >
                                {UsersListRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                    </Box>
                }
        </Card>
    );
};

export default UsersList;
