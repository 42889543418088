import React from 'react';
import {Field, Form} from 'react-final-form';
import {Button, Grid, Modal, Typography} from "@material-ui/core";
import {useAuth0} from "@auth0/auth0-react";
import FinalFormTextField from "../../../../atoms/FinalFormTextField";
import Validation, {translate} from "../../../../../state/utils/Validation";
import {managerUserStoreValidation} from "../../../../../state/ducks/manager/managerUsers/validation";
import {empty, mergeWithDefaultForm} from "../../../../../state/utils/Common";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";
import {managerUserForms} from "../../../../../state/ducks/manager/managerUsers";

/**
 * モーダルコンポーネントのスタイル
 */
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

/**
 * ユーザーの作成および更新アクションのためのユーザーモーダル。
 *
 * @param className - コンポーネントのスタイル
 * @param {boolean} isOpen - "モーダルが開いているか？"フラグ
 * @param {managerViewRegisterToggle} setClose - モーダルを閉じるアクション
 * @param {Object} activeUser - (編集モード）選択されたユーザー
 * @param {managerRegisterNewUser} userRegister - ユーザー登録アクション
 * @param {boolean} RegisterLoading - 登録読み込み状態
 * @param {Object} RegisterError - 登録エラー内容
 * @param {managerUpdateUser} updateUser - ユーザー更新のアクション
 * @param {*} t -  翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const UserModal = ({
                       className,
                       isOpen,
                       setClose,
                       activeUser,
                       userRegister,
                       RegisterLoading,
                       RegisterError,
                       updateUser,
                       t
                   }) => {
    const [modalStyle] = React.useState(getModalStyle);
    // グローバルテーマオブジェクトのロード
    const globalStyle = globalTheme._default();
    // auth0アクセストークンの取得
    const {getAccessTokenSilently} = useAuth0();
    return (
        <Modal open={isOpen}
               onClose={setClose}
               disableBackdropClick
               className={RegisterLoading && globalStyle.loading}
        >
            <div className={globalStyle.modal} style={modalStyle}>
                <Typography variant="h2" style={{'paddingBottom': 30}}
                            id="simple-modal-title">{!empty(activeUser) ? t('users.update.title') : t('users.create.title')}</Typography>
                <Form
                    onSubmit={e => {
                        e = mergeWithDefaultForm(e, managerUserForms.createEditForm)
                        // 有効ユーザーデータは、モーダルがEDITやCREATEモードであるかどうかをチェックします。
                        if (!activeUser) {
                            // アカウント登録
                            if (!RegisterLoading) {
                                (async () => {
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    userRegister(e, access_token);
                                })()
                            }
                        } else {
                            // アカウントの更新
                            if (!RegisterLoading) {
                                (async () => {
                                    e.uuid = activeUser.uuid;
                                    const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                                        () => {
                                            return getAccessTokenSilently();
                                        },
                                    );
                                    updateUser(activeUser, e, access_token);
                                })()
                            }
                        }

                    }}
                    // ここでフォームデータをバリデーションしてから、キーを翻訳します
                    validate={e => {
                        return translate(t, Validation({...e}, managerUserStoreValidation))
                    }}
                    // ここでフォームの初期値を扱う
                    initialValues={{
                        last_name: !empty(activeUser) ? activeUser.last_name : "",
                        first_name: !empty(activeUser) ? activeUser.first_name : "",
                        email: !empty(activeUser) ? activeUser.email : ""
                    }}
                    // ここでは、フォームのレンダリングと制御を行います。
                    // エラー処理やダブルクリック防止などはここで行います。
                    render={({handleSubmit, form, submitting, pristine, invalid, values}) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        required
                                        size={"small"}
                                        name="last_name"
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('users.last_name')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        required
                                        size={"small"}
                                        value={!empty(activeUser) && activeUser.first_name}
                                        name="first_name"
                                        component={FinalFormTextField}
                                        type="text"
                                        label={t('users.first_name')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="email"
                                        fullWidth
                                        required
                                        size={"small"}
                                        value={!empty(activeUser) && activeUser.email}
                                        component={FinalFormTextField}
                                        type="email"
                                        label={t('users.email_address')}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop: 0}}>
                                    <Typography className={globalStyle.error}>
                                        {t(RegisterError?.translation_error_key)}
                                    </Typography>
                                </Grid>
                                <Grid item container spacing={3} justify="flex-end">
                                    <Grid item>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            disabled={RegisterLoading}
                                            onClick={setClose}
                                        >
                                            {t('actions.cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={submitting || invalid || pristine}
                                        >
                                            {/* 読み込み状態と更新・作成状態での登録ボタンのテキスト変更 */}
                                            {RegisterLoading ? t('actions.sending') : !empty(activeUser) ? t('actions.change') : t('actions.add')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>)}
                />
            </div>

        </Modal>
    )
};

export default UserModal;
