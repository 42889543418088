import {makeStyles} from "@material-ui/core";

/***
 * 通常ユーザー側のレイアウト特定スタイリングはここで行われます
 *
 * @type {(props?: any) => ClassNameMap<"tableHeader"|"navHeaderBackground">}
 */
export const commonStyles = makeStyles((theme) => ({
    tableHeader: {
        backgroundColor: theme.palette.background.dark,
        paddingLeft: 21,
        paddingTop: 13,
        paddingBottom: 13,
        paddingRight: 29,
        borderBottom: "1px #CFCFCF solid",
        "& div": {
            "& p": {
                fontSize: "12px",
                fontWeight: "900",
            }
        }
    },
    navHeaderBackground: {
        backgroundColor: theme.palette.background.navHeader,
        color: "#000000",
        borderBottom: "1px #E5E5E5 solid"
    }
}));

const common = {
    commonStyles
}
export default common;
