import React, {useEffect} from 'react';
import {Box, Button, Container, Grid, makeStyles, Typography} from '@material-ui/core';
import Page from "../Page";
import UsersList from "./list/UsersList";
import Toolbar from "./Toolbar";
import {loadUserList} from "../../../../state/ducks/user/users/actions";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch} from "react-redux";
import {empty} from "../../../../state/utils/Common";
import UserModal from "./modal/UserModal";
import {ConfirmationModal} from "../../../atoms/ConfirmationModal";
import Auth0Utils from "../../../../state/utils/Auth0Utils";

/**
 * /manager/users コンポーネントスティール
 */
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2)
    },
    button: {
        fontWeight: 900,
}}));
/**
 * ユーザー一覧画面コンポーネント
 *
 * @param {boolean} loading - ユーザー一覧のロード状態
 * @param {Object} activeProfile - ローグインユーザー
 * @param {Object} profile - ログインユーザープロフィール。
 * @param {*} updateUser - 更新ユーザーアクション
 * @param {Object} error - ページエラー
 * @param {boolean} isRegisterView = "" "Is the register modal open?" flag
 * @param {boolean} toggleRegisterView - 新規ユーザーアカウントのモダールトグル
 * @param {*} userRegistrationLoad - ユーザーの登録ロード状態
 * @param {Object} registerError - モーダルのエラー
 * @param {*} registerUser - 新規ユーザー登録リクエスト
 * @param {boolean} searchLoad - ユーザー検索中のアクション
 * @param {Object} searchUserResult - user list from result
 * @param {boolean} reLoad - double load checker - ダブルクリック対策
 * @param {*} searchUser - ユーザー検索アクション
 * @param {Object} users - 現在のユーザー
 * @param {number} userList_CurrentPage - 現在のページ
 * @param {boolean} deleteView - 削除の表示・非表示状態
 * @param {boolean} deleteLoading - 削除中の確認
 * @param {Object} deleteError  - 削除エラー
 * @param {*} viewUserDeleteToggle - 削除モーダルの表示・非表示状態
 * @param {*} deleteUsers - ユーザー削除アクション
 * @param {*} changePage - ユーザー編集アクション
 * @param {*} t - translation
 * @returns {JSX.Element}
 * @constructor
 */
const UserView = (
    {

        loading,
        activeProfile,
        profile,
        updateUser,
        error,
        isRegisterView,
        toggleRegisterView,
        userRegistrationLoad,
        registerError,
        registerUser,
        searchLoad,
        searchUserResult,
        reLoad,
        searchUser,
        users,
        userList_CurrentPage,
        deleteView,
        deleteLoading,
        deleteError,
        viewUserDeleteToggle,
        deleteUsers,
        changePage,
        t
    }) => {
    // auth0 アクショントーケンを取得
    const {getAccessTokenSilently} = useAuth0();
    //アプリケーションテーマを取得
    const dispatch = useDispatch();
    useEffect(() => {
        if (reLoad && !empty(profile)) {
            (async () => {
                const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                    () => {
                        return getAccessTokenSilently();
                    },
                );
                dispatch(loadUserList(access_token, profile?.account?.uuid));
            })();
        }
    }, [dispatch, getAccessTokenSilently, profile, reLoad])
    const classes = useStyles();
    return (
        <Page
            className={classes.root}
            title={t('users.title')}
        >
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h2">{t('users.title')}</Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify="flex-end">
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            data-testid="create-button"
                            onClick={() => toggleRegisterView()}
                        >
                            {t('users.new_account')}
                        </Button>
                    </Grid>
                </Grid>
                {/* 作成/編集 モーダル コンポーネント */}
                <UserModal
                    isOpen={isRegisterView}
                    setClose={toggleRegisterView}
                    userRegister={registerUser}
                    registerLoading={userRegistrationLoad}
                    activeUser={activeProfile}
                    profile={profile}
                    registerError={registerError}
                    userEdit={updateUser}
                    t={t}
                />
                {/* 削除モーダルコンポーネント */}
                <ConfirmationModal
                    isOpen={deleteView}
                    isLoading={deleteLoading}
                    error={deleteError?.translation_error_key}
                    profile={profile}
                    loadingText={t('actions.sending')}
                    setClose={viewUserDeleteToggle}
                    confirmationTitle={t('users.delete.title')}
                    confirmationDescription={t('users.delete.description')}
                    buttonText={t('actions.delete')}
                    id={activeProfile !== null && activeProfile.uuid}
                    deleteInformation={t('users.delete.delete_account_title', {user: activeProfile !== null ? (activeProfile.last_name + " " + activeProfile.first_name) : null})}
                    event={deleteUsers}
                    cancelText={t('actions.cancel')}
                />
                <Toolbar toggleRegisterView={toggleRegisterView} searchLoad={searchLoad}
                         searchUserResult={searchUserResult} searchUser={searchUser}/>
                <Box mt={3}>
                    {/* ユーザー一覧 */}
                    <UsersList
                        loading={loading}
                        users={users}
                        viewRegisterToggle={toggleRegisterView}
                        viewDeleteToggle={viewUserDeleteToggle}
                        currentPage={userList_CurrentPage}
                        changePage={changePage}
                        t={t}/>
                </Box>
            </Container>
        </Page>
    );
};

export default UserView;
