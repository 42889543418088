import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Box, Hidden, IconButton, makeStyles, Toolbar, Typography} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {withTranslation} from "react-i18next";
import {commonStyles} from '../../theme/user/styles/common'
import {ReactComponent as Logo} from "../../../assests/img/logo.svg";
import LogoutMenu from "../../atoms/LogoutMenu";

/**
 * ヘッダースタイル
 */
const useStyles = makeStyles(() => ({
    root: {},
    title: {
        fontSize: 16,
        fontWeight: "Bold"
    },
}));

/**
 * 通常ユーザー側のヘッダーコンポーネント
 * @param {string} className - 親からのクラス名
 * @param {boolean} onMobileNavOpen - スマートフォンのナビメニュー開閉
 * @param {*} t - 翻訳ファイル
 * @param {Object} managerProfile - ログインユーザープロフィールデータ
 * @param {*} i18n - 翻訳ファイル
 * @param {*} rest - 他の変数
 */
const TopBar = ({
                    className,
                    onMobileNavOpen,
                    t,
                    managerProfile,
                    i18n,
                    ...rest
                }) => {
    const classes = useStyles();
    // アプリケーションテーマ
    const styles = commonStyles();

    return (
        <AppBar
            elevation={0}
            color={"inherit"}
            className={styles.navHeaderBackground}
        >
            <Toolbar>
                <Logo style={{marginRight: 10}}>

                </Logo>
                <Typography component="h1" variant='h5' color="inherit" noWrap className={classes.title}>
                    {t('root.company_name')} {t('root.application')}
                </Typography>
                <Box flexGrow={1}/>
                <Hidden mdDown>
                    <LogoutMenu
                        companyName={managerProfile?.account?.name}
                        userName={managerProfile?.last_name + " " + managerProfile?.first_name}
                        signOutUri={"/sign_out"}
                        t={t}
                    />
                </Hidden>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default withTranslation('common')(TopBar);
