import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {serviceContractOperations} from "../../../../state/ducks/user/service_contracts";
import ServiceContractView from "../../../components/user/serviceContracts/Page";

/**
 * Reduxステート（これはコンポーネントのパラメータに挿入されます。)
 * @param state - reduxルートクラス
 */
const mapStateToProps = state => {
    return {
        reLoad: state.root.reLoad,
        profile: state.root.managerProfile,

        loading: state.serviceContract.loading,
        isError: state.serviceContract.isError,
        error: state.serviceContract.error,
        serviceContractList: state.serviceContract.serviceContractList,
        activeServiceContractError: state.serviceContract.activeServiceContractError
    }
}

/**
 * Reduxアクション（これもコンポーネントのパラメータに挿入されます。)
 */
const mapEventToProps = {
    loadServiceContractList: serviceContractOperations.loadServiceContractList,
}

/**
 * Redux
 * i18next Translations
 * を
 * ServiceContractView コンポーネントに追加する。
 */
const ServiceContractsContainer = compose(
    connect(mapStateToProps, mapEventToProps),
    withTranslation('common'))(ServiceContractView)

export default ServiceContractsContainer;
