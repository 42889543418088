import clsx from "clsx";
import {Box, Card, Grid, makeStyles, Typography,} from "@material-ui/core";
import React from "react";
import {empty} from "../../../../../state/utils/Common";
import Loading from "../../../../atoms/Loading";
import {managerCommonStyles} from "../../../../theme/manager/styles/common";
import globalTheme from "../../../../theme/globalTheme";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList} from "react-window";
import {AccountsListRow} from "./AccountsListRow";

/**
 * 管理者側のアカウントリスト コンポーネントのスタイルクラス
 */
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        border: "1px #CCCCCC solid",
        boxShadow: "none"
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pagination: {
        '& .MuiPaginationItem-page': {
            color: "#7E7E7E",
            backgroundColor: "#F2F2F2",
        },
        '& .Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
        },
    },

}));

/**
 * 管理者側のアカウント一覧
 *
 * @param {Object} className - 管理者クラスのアカウントリスト
 * @param {number} currentPage - 現在のページネーションページ
 * @param {*} managerChangePageAccountList - ページ変更時のイベント
 * @param {Object} accounts - ロードされたアカウントレコード
 * @param {*} toggleDelete - モーダル削除表示・非表示
 * @param {boolean} managerViewEditToggle - アカウント編集アクション
 * @param {boolean} managerLoading - 読み込みイベント
 * @param {*} t - 翻訳ファイル
 * @returns {JSX.Element}
 * @constructor
 */
const AccountsList =
    ({
         className,
         currentPage,
         managerChangePageAccountList,
         accounts,
         toggleDelete,
         managerViewEditToggle,
         managerLoading,
         t
     }) => {
        // 編集ボタンをクリックすると
        function handleEditClick(id) {
            managerViewEditToggle(id)
        }

        // ボタンの削除クリック時
        function handleDeleteClick(id) {
            toggleDelete(id)
        }

        // アカウントリストのグローバルスタイルクラス
        const classes = useStyles();
        const common = managerCommonStyles();
        const _globalTheme = globalTheme._default();
        return (
            <Card
                className={clsx(classes.root, className)}
            >
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                                style={{fontWeight: 900}}
                            >
                                {t('accounts.list')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container className={common.tableHeader}>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.list_headers.id')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.list_headers.supplier_name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.list_headers.supplier_id')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: "bold"}}>
                            {t('accounts.list_headers.note')}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>

                    </Grid>
                </Grid>
                {managerLoading === true ?
                    <Box className={_globalTheme.list} display="flex" alignItems="center">
                        <Grid container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              data-testid="loading"
                        >
                            <Grid item xs={12}>
                                <Loading/>
                            </Grid>
                        </Grid>
                    </Box>
                    : !empty(accounts) && !empty(accounts.accounts) &&
                    <Box className={_globalTheme.list} data-testid="has-accounts">
                        <AutoSizer>
                            {({height, width}) => (
                                <FixedSizeList
                                    width={width}
                                    height={height}
                                    itemCount={accounts.accounts.length}
                                    itemSize={45}
                                    itemData={{
                                        accounts: accounts.accounts,
                                        t: t,
                                        handleEditClick: (id) => handleEditClick(id),
                                        handleDeleteClick: (id) => handleDeleteClick(id)
                                    }}
                                >
                                    {AccountsListRow}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </Box>
                }
            </Card>
        );
    };

export default AccountsList;
