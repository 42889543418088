import React from 'react';
import {ThemeProvider} from '@material-ui/core';
import managerTheme from './theme/manager'
import userTheme from './theme/user'
import GlobalStyles from "./GlobalStyles";
import DashboardLayout from "./layout/DashboardLayout/DashboardLayout";
import ManagerDashboardLayout from "./layout/ManagerDashboardLayout/ManagerDashboardLayout";
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {rootOperations} from "../state/ducks/root";
import ManagerSignOutPage from "./components/manager/SignOutPage";
import {empty} from "../state/utils/Common";
import {useAuth0} from "@auth0/auth0-react";
import BlankLayout from "./layout/BlankLayout/Blank";
import Auth0Utils from "../state/utils/Auth0Utils";
import ErrorLayout from "./layout/ErrorLayout/Error";
import SignOutPage from "./components/user/SignOutPage";
import {ErrorBoundary} from "react-error-boundary";
import {ProtectedRoute} from "../PrivateRoute";

/**
 * 選択されたユーザーのRedux状態をコンポーネントに描画
 *
 * @param state
 * @returns {{isError, managerProfile, errorDetail: *}}
 */
const mapStateToProps = state => {
    return {
        isError: state.root.isError,
        errorDetail: state.root.errorDetail,
        managerProfile: state.root.managerProfile,
        accessTokenFailureDialog: state.root.accessTokenFailureDialog
    }
}
/**
 * アクションやオペレーションをreduxからここに描く
 */
const mapEventToProps = {
    errorConfirm: rootOperations.errorConfirm,
    getProfile: rootOperations.getProfile
}
/**
 * メインビューコンポーネント、すべてのビューはここからロードされます。
 * これは現在、正しいテーマとテンプレートを読み込むために、現在のURLをチェックしています。
 *  注：プロファイルが読み込めない場合は何も表示されません。
 *
 * @param isError - /ducks/root/reducer redux - アプリケーションロード時の状態
 * @param errorDetail - /ducks/root/reducer redux - アプリケーションのエラーメッセージ
 * @param errorConfirm - /ducks/root/operation redux - エラーメッセージの確認
 * @param t - i18next翻訳ライブラリ
 * @param location - react-router-dom - 現在のURL
 * @param getProfile - /ducks/root/operation redux -  APIからプロファイルを取得
 * @param managerProfile - /ducks/root/reducer redux - 現在のプロファイルの状態
 * @param accessTokenFailureDialog
 * @returns {JSX.Element} - BlankLayout|ManagerDashboardLayout|DashboardLayout
 * @constructor
 */
const AppContainer = ({
                          isError,
                          errorDetail,
                          errorConfirm,
                          t,
                          location,
                          getProfile,
                          managerProfile,
                          accessTokenFailureDialog
                      }) => {
    const {user, getAccessTokenSilently} = useAuth0();
    // url == managerでユーザーが管理者でない場合、ユーザーダッシュボードにリダイレクトする。
    if (window.location.href.includes("manager") && !empty(managerProfile) && managerProfile?.is_user === true) {
        return (
            <div>
                <Redirect to={'/service_contracts'}/>
            </div>
        )
    } else if (!window.location.href.includes("manager") && !empty(managerProfile) && managerProfile?.is_user === false) {
        // url == userでユーザーが通常ユーザーでない場合、管理者ダッシュボードにリダイレクトする。
        return (
            <div>
                <Redirect to={'/manager/accounts'}/>
            </div>
        )
    }



    // ログイン後にAPIからログインユーザーのプロファイルを読み込む
    if (!empty(user) && empty(managerProfile)) {
        (async () => {
            const accessToken = await Auth0Utils.checkAndUpdateAccessToken(
                () => {
                    return getAccessTokenSilently();
                }
            );
            getProfile(user.sub, accessToken);
        })();
    }

    return (
        <div>
            <GlobalStyles/>
            <ErrorBoundary fallbackRender={props => <ErrorLayout {...props} t={t} title={t("error.application_error")} message={t("error.access_token_description")} />} onError={(error,info) => {
                console.log(error);
                console.log(info);
                //FIXME: SEND ERROR TO LOG VIA API HERE
            }}>
            {accessTokenFailureDialog === true || isError === true ?
                <ErrorLayout
                    title={t("error.application_error")}
                    error_code={errorDetail.title}
                    message={!empty(errorDetail) ?
                        t(errorDetail.translation_error_key) :
                        t("error.access_token_description")
                    }
                    t={t}
                />
                :
                <Switch>
                    <Route exact path="/manager/sign_out">
                        <ManagerSignOutPage/>
                    </Route>
                    <Route exact path='/sign_out'>
                        <SignOutPage/>
                    </Route>
                    <Route exact path='/'>
                        <ThemeProvider theme={managerTheme}>
                            <BlankLayout getProfile={getProfile} managerProfile={managerProfile}/>
                        </ThemeProvider>
                    </Route>

                    <Route exact path='/manager'>
                        <ThemeProvider theme={managerTheme}>
                            <BlankLayout getProfile={getProfile} managerProfile={managerProfile}/>
                        </ThemeProvider>
                    </Route>
                    <Route path='/manager'>
                        <ProtectedRoute redirectTo={"/manager"} component={ () => (
                            <ThemeProvider theme={managerTheme}>
                                {empty(managerProfile) || managerProfile?.is_user === true ? <div/> :
                                    <ManagerDashboardLayout getProfile={getProfile} managerProfile={managerProfile}/>
                                }
                            </ThemeProvider>
                        )}/>
                    </Route>
                    <Route path='/'>
                        <ProtectedRoute redirectTo={"/"} component={() => (
                            <ThemeProvider theme={userTheme}>
                                {empty(managerProfile) || managerProfile?.is_user === false ? <div/> :
                                    <DashboardLayout getProfile={getProfile} managerProfile={managerProfile}/>
                                }
                            </ThemeProvider>
                        )}/>
                    </Route>
                </Switch>
            }
            </ErrorBoundary>
        </div>
    )
}

/**
 * ロードされたreduxのステートとプロップを上記のコンポーネントにプッシュします。
 * 注：翻訳ライブラリとルーターライブラリもここで呼ばれます。
 */

export const App = compose(
    connect(mapStateToProps, mapEventToProps),
    withRouter,
    withTranslation('common'))(AppContainer)
