/**
 * //////////////////////////////////
 * フォームテンプレー
 * /////////////////////////////////
 *
 * コンポーネントのフォームテンプレはここに書いています。
 * ＨＴＭＬフォームで未入力状態でフォーム送信を実行すればフォームの入力キーが無くなってしまうので、nullフィールドがあっても、
 * 必ずフォームテンプレを送信するようにここにファイルが必要です。
 */

/**
 * 新規フォーム
 * @type {{notes: null, sf_account: null, sf_account_user: null}}
 */
const createForm = {
    sf_account: null,
    sf_account_user: null,
    notes: null
}

/**
 * 編集フォーム
 * @type {{note: null, name: null}}
 */
const editForm = {
    name: null,
    note: null
}

/**
 *
 * @type {{createForm: {notes: null, sf_account: null, sf_account_user: null}, editForm: {note: null, name: null}}}
 */
export const forms = {
    createForm,
    editForm
}
export default forms
