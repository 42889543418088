/**
 * ////////////////////////////////////////
 * MATERIAL UI THEME OVERRIDES (コンポーネント属性の上書き)
 * ------------------------------------
 *
 * デフォルトのMaterial UIモジュールのスタイルを上書きするモジュール
 * ////////////////////////////////////////
 */
const overrides = {
    MuiButton: { // Name of the component ⚛️ / style sheet
        root: { // Name of the rule
            fontSize: 13, // Some CSS
            fontWeight: 900
        },
    },
    MuiFormLabel: {
        root: {
            color: "#000000",
            fontWeight: 900
        }
    },
    MuiContainer: {
        root: {
            '@media (min-width: 600px)' : {
                paddingLeft: 31,
                paddingRight: 31
            }
        }
    },
    MuiToolbar: {
        gutters: {
            '@media (min-width: 600px)': {
                paddingLeft: 31,
                paddingRight: 31
            }
        }
    }
}
export default overrides;
