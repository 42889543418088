import React from 'react';
import {Button, Card, CardContent, CardHeader, Grid, makeStyles, Typography} from '@material-ui/core';
import AddressChangeModal from "../modal/AddressChangeModal";
import {useAuth0} from "@auth0/auth0-react";
import globalTheme from "../../../../theme/globalTheme";
import Auth0Utils from "../../../../../state/utils/Auth0Utils";

/**
 * コンポーネントスティール
 */
const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "900"
    }
}));

/**
 * 契約詳細の住所表示コンポーネント（住所変更モーダルボックスと含め）
 *
 * @param {string} title - コンポーネントカードのタイトル
 * @param {string} edit_title - 住所編集のボタン
 * @param {Object} current_address - 現在の利用している住所
 * @param {boolean} loading - 住所一覧のロード状態
 * @param {boolean} editIsOpen - 住所編集の表示・非表示状態
 * @param {*} editModalToggleEvent - モダールの開閉アクション
 * @param {Object} contactAddresses - 取得出来た選択可能の住所データ
 * @param {*} loadServiceContractContactAddress - 住所ロード状態
 * @param {Object} activeAddress - 選択した住所
 * @param serviceContractContactAddressSelect - 住所の選択アクション
 * @param {string} contactKey - 担当者のタイプ (請求｜アカウント)
 * @param {Object} contactData - 担当者のデータ
 * @param {string} serviceContractID - 利用契約ID
 * @param {Object} managerProfile - ログインしたユーザープロフィール
 * @param {*} addressEditUpdateLoading - 住所編集のロード状態
 * @param {*} updateServiceContractContactAddress - 住所更新アクション
 * @param addressEditError - 住所編集の更新エラー
 * @param t - 翻訳ファイル。
 * @returns {JSX.Element}
 * @constructor
 */
const Address = (
    {
        title,
        edit_title,
        current_address,
        loading,
        editIsOpen,
        editModalToggleEvent,
        contactAddresses,
        loadServiceContractContactAddress,
        activeAddress,
        serviceContractContactAddressSelect,
        contactKey,
        contactData,
        serviceContractID,
        managerProfile,
        addressEditUpdateLoading,
        updateServiceContractContactAddress,
        addressEditError,
        t
    }) => {
    // auth0アクセストークン取得
    const {getAccessTokenSilently} = useAuth0();
    // グローバルクラス
    const classes = useStyles();
    // アプリケーションテーマのロード
    const cardStyle = globalTheme.defaultCards();
    return (
        <Card>
            {/* 住所変更モーダル表示スウィッチ */}
            <CardHeader title={title} className={cardStyle.headers} action={
                <Button variant="contained" color="secondary" style={{marginTop: 10}} onClick={() => {
                    (async () => {
                        const access_token = await Auth0Utils.checkAndUpdateAccessToken(
                            () => {
                                return getAccessTokenSilently();
                            },
                        );
                        loadServiceContractContactAddress(() => (editModalToggleEvent), contactData?.uuid, serviceContractID, access_token, managerProfile?.account?.uuid);
                    })()
                }}>
                    {t('actions.edit')}
                </Button>
            }/>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={3}><Typography variant="body1"
                                                  className={classes.title}>{t('service_contracts.detail.address.address')}</Typography></Grid>
                    <Grid item xs={9}>
                        {current_address &&
                            <Typography variant="body1">
                                {t('address.post_code_sign')}{current_address.postal_code}
                                {current_address.state}
                                {current_address.city}
                                {current_address.address}
                                {current_address.name}
                            </Typography>
                        }
                    </Grid>
                </Grid>
            </CardContent>
            {/* 住所変更モーダル */}
            <AddressChangeModal
                contactKey={contactKey}
                title={edit_title}
                load={loading}
                isOpen={editIsOpen}
                setClose={editModalToggleEvent}
                contactAddresses={contactAddresses}
                activeAddress={activeAddress}
                serviceContractContactAddressSelect={serviceContractContactAddressSelect}
                t={t}
                contactData={contactData}
                serviceContractID={serviceContractID}
                managerProfile={managerProfile}
                addressEditUpdateLoading={addressEditUpdateLoading}
                updateServiceContractContactAddress={updateServiceContractContactAddress}
                addressEditError={addressEditError}
            />
        </Card>
    );
};

export default Address;
