import types from "./types";

/***
 * /////////////////////////////////////////
 * REDUCERS (レデューサー)
 * -------------------
 *
 * すべてのリアクトのマネージャーアカウントの一時的なデータが保存される領域。
 * 全てのアプリケーションでグローバルに使用されます。
 *
 * Reducerイベントは、ステートへのセッター命令のみを **実行すべき** です。
 *
 * このReducerのステートは、/src/state/ducks/index.jsに設定されているmanagerAccountsグループに束縛されています。
 * //////////////////////////////////////////
 */
export const serviceContractState = {
    loading: true,
    isError: false,
    error: {},
    serviceContractList_CurrentPage: 0,
    serviceContractList: {},
    activeServiceContract: {},
    activeServiceContractContact: {},
    activeServiceContractError: {},
    contactBilledToEditModal: false,
    contactSoldToEditModal: false,
    editLoading: false,
    editError: {},
    contactLoading: true,
    contactEditInitLoad: true,

    addressEditLoading: true,
    addressEditUpdateLoading: false,
    addressEditError: {},
    addressBilledToEditModal: false,
    addressSoldToEditModal: false,
    contactAddresses: {},
    activeAddress: {},
    selectedAddress: {}
}

export default function serviceContractsList(state = serviceContractState, action) {
    switch (action.type) {
        // -----------------------------------------------
        // 利用契約一覧取得
        // -----------------------------------------------
        case types.REQUEST_SERVICE_CONTRACTS_LOADING:
            return {
                ...state,
                loading: state.loading = true,
                isError: state.isError = false,
                error: state.error = {}
            }
        case types.REQUEST_SERVICE_CONTRACTS_SUCCESS:
            return {
                ...state,
                loading: state.loading = false,
                serviceContractList: state.serviceContractList = action.payload.serviceContracts,
                isError: state.isError = false,
                error: state.error = {},
            }
        case types.REQUEST_SERVICE_CONTRACTS_FAILURE:
            return {
                ...state,
                loading: state.loading = false,
                isError: state.isError = true,
                error: state.error = action.payload.error
            }
        // -----------------------------------------------
        // 利用契約詳細取得
        // -----------------------------------------------
        case types.EXTRACT_SERVICE_CONTRACT_DETAIL:
            return {
                ...state,
                activeServiceContract: state.activeServiceContract = action.payload.serviceContract
            }
        // -----------------------------------------------
        // 連絡先変更モダールの表示・非表示
        // -----------------------------------------------
        case types.TOGGLE_SOLD_TO_MODAL:
            return {
                ...state,
                contactSoldToEditModal: !state.contactSoldToEditModal
            }
        case types.TOGGLE_BILLED_TO_MODAL:
            return {
                ...state,
                contactBilledToEditModal: !state.contactBilledToEditModal
            }
        // -----------------------------------------------
        // 連絡先変更モーダル
        // -----------------------------------------------
        case types.UPDATE_SERVICE_CONTRACT_CONTACT_LOADING:
            return {
                ...state,
                editLoading: state.editLoading = true,
                editError: state.editError = {}
            }
        case types.UPDATE_SERVICE_CONTRACT_CONTACT_SUCCESS:
            return {
                ...state,
                editLoading: state.editLoading = false,
                contactSoldToEditModal: state.contactSoldToEditModal = false,
                contactBilledToEditModal: state.contactBilledToEditModal = false
            }
        case types.UPDATE_SERVICE_CONTRACT_CONTACT_FAILED:
            return {
                ...state,
                editLoading: state.editLoading = false,
                editError: state.editError = action.payload.error,
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_LOADING:
            return {
                ...state,
                contactLoading: state.contactLoading = true,
                activeServiceContractError: state.activeServiceContractError = {}
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_SUCCESS:
            return {
                ...state,
                contactLoading: state.contactLoading = false,
                activeServiceContract: state.activeServiceContract = action.payload.activeServiceContract
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_FAILED:
            return {
                ...state,
                contactLoading: state.contactLoading = false,
                activeServiceContract: state.activeServiceContract = {},
                activeServiceContractError: state.activeServiceContractError = action.payload.error
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_LOADING:
            return {
                ...state,
                contactEditInitLoad: state.contactEditInitLoad = true,
                activeServiceContractContact: state.activeServiceContractContact = {},
                editError: state.editError = {}
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_SUCCESS:

            return {
                ...state,
                contactEditInitLoad: state.contactEditInitLoad = false,
                activeServiceContractContact: state.activeServiceContractContact = action.payload.activeContact
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_FAILED:
            return {
                ...state,
                contactEditInitLoad: state.contactEditInitLoad = false,
                editError: state.editError = action.payload.error
            }
        // -----------------------------------------------
        // 連絡先の住所変更モーダル
        // -----------------------------------------------
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_BILLED_TO:
            return {
                ...state,
                addressBilledToEditModal: !state.addressBilledToEditModal
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_TOGGLE_SOLD_TO:
            return {
                ...state,
                addressSoldToEditModal: !state.addressSoldToEditModal
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SELECT:
            return {
                ...state,
                activeAddress: state.activeAddress = action.payload.activeAddress
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING:
            return {
                ...state,
                addressEditLoading: state.addressEditLoading = true,
                addressEditError: state.addressEditError = {},
                contactAddresses: state.contactAddresses = {},
                activeAddress: state.activeAddress = {},
                selectedAddress: state.selectedAddress = {}
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS:
            return {
                ...state,
                addressEditLoading: state.addressEditLoading = false,
                addressEditError: state.addressEditError = {},
                contactAddresses: state.contactAddresses = action.payload.addressList
            }
        case types.REQUEST_CONTACT_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED:
            return {
                ...state,
                addressEditLoading: state.addressEditLoading = false,
                addressEditError: state.addressEditError = action.payload.error,
            }
        case types.UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_LOADING:
            return {
                ...state,
                addressEditUpdateLoading: state.addressEditUpdateLoading = true,
            }
        case types.UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_SUCCESS:
            return {
                ...state,
                addressEditUpdateLoading: state.addressEditUpdateLoading = false,
                addressSoldToEditModal: state.addressSoldToEditModal = false,
                addressBilledToEditModal: state.addressBilledToEditModal = false
            }
        case types.UPDATE_SERVICE_CONTRACT_CONTACT_ADDRESS_FAILED:
            return {
                ...state,
                addressEditUpdateLoading: state.addressEditUpdateLoading = false,
                addressEditError: state.addressEditError = action.payload.error
            }
        default:
            return state
    }
}
